import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Theme from "../constants/Theme";
import { isMobileDevice } from "../utils/browser.util";
import { FC } from "react";
import { Dayjs } from "dayjs";
import { GROUPS } from "../constants/constants";

interface IFilterProps {
  email: string;
  jobStatus: string;
  toDate: Dayjs | null;
  fromDate: Dayjs | null;
  fetchingOrderList: boolean;
  group: string;
  handleFilter: () => void;
  handleEmailOnChange: (email: any) => void;
  handleToDateOnChange: (value: Dayjs | null) => void;
  handleFromDateOnChange: (value: Dayjs | null) => void;
  handleJobStatusOnChange: (event: SelectChangeEvent) => void;
  handleGroupsOnChange: (event: SelectChangeEvent) => void;
}

const Filter: FC<IFilterProps> = ({
  email,
  toDate,
  fromDate,
  jobStatus,
  fetchingOrderList,
  group,
  handleFilter,
  handleEmailOnChange,
  handleToDateOnChange,
  handleFromDateOnChange,
  handleJobStatusOnChange,
  handleGroupsOnChange,
}) => {
  const isMobile = isMobileDevice();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        // position: "fixed",
        // background: Theme.COLORS.BLACK_RUSSIAN,
        width: "100%",
        zIndex: 1,
        // top: 78.5,
        // p: 1,
        // left: 256,
      }}
    >
      <TextField
        fullWidth
        label="Customer Email"
        color="primary"
        value={email}
        inputProps={{
          style: { color: Theme.COLORS.BLACK_RUSSIAN },
        }}
        sx={{ flex: 1, mr: 2, mt: 1, mb: isMobile ? 1 : 0 }}
        onChange={(e) => handleEmailOnChange(e)}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker", "DatePicker"]}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(newValue) => handleFromDateOnChange(newValue)}
          />
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={(newValue) => handleToDateOnChange(newValue)}
          />
        </DemoContainer>
      </LocalizationProvider>

      <Box
        sx={{
          ml: isMobile ? 0 : 2,
          mt: isMobile ? 2 : 1,
          flex: 1,
          mb: isMobile ? 1 : 0,
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={jobStatus}
            label="Order Status"
            onChange={handleJobStatusOnChange}
          >
            <MenuItem value="PENDING">Pending</MenuItem>
            <MenuItem value="UPLOAD_PHOTOS">Upload Photos</MenuItem>
            <MenuItem value="REVIEWING">Reviewing</MenuItem>
            <MenuItem value="PROCESSING">Processing</MenuItem>
            <MenuItem value="COMPLETED">Completed</MenuItem>
            <MenuItem value="DONE">Done</MenuItem>
            <MenuItem value="ERROR">Error</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          ml: isMobile ? 0 : 2,
          mt: isMobile ? 2 : 1,
          flex: 1,
          mb: isMobile ? 1 : 0,
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Assigned To</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={group}
            label="Assigned To"
            onChange={handleGroupsOnChange}
          >
            {GROUPS.map((group) => (
              <MenuItem value={group.value}>{group.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Button
        disabled={fetchingOrderList}
        variant="contained"
        sx={{
          mt: 1,
          height: 55,
          fontSize: 14,
          fontWeight: "bold",
          flex: 1,
          ml: isMobile ? 0 : 2,
          bgcolor: Theme.COLORS.IRIS_BLUE,
          color: Theme.COLORS.TEXT_PRIMARY_COLOR,
        }}
        onClick={handleFilter}
      >
        Filter
      </Button>
    </Box>
  );
};

export default Filter;
