import axios from "axios";
import useSnackbar from "./useSnackbar";

const useGoogleFirestore = () => {
  const { showSnackbar } = useSnackbar();

  const checkUserExists = async (payload: any, token: string) => {
    try {
      const url =
        "https://us-central1-mas-lensify.cloudfunctions.net/api/user/exists";
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log("[Error] user exists", error.message);
      return error.message;
    }
  };

  const addNewUser = async (payload: any, token: string) => {
    try {
      const url =
        "https://us-central1-mas-lensify.cloudfunctions.net/api/user/add-new";
      await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error: any) {
      console.log("[Error] add user", error.message);
      return error.message;
    }
  };

  const addDreamAvatarsStylesData = async (token: string, payload: any) => {
    try {
      const url =
        "https://us-central1-mas-lensify.cloudfunctions.net/api/dream-avatars/add-styles-data";
      await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      showSnackbar("Operation successful", "success");
    } catch (error: any) {
      console.log("[Error] add dream avatars data", error.message);
      showSnackbar(`Operation Failed: ${error.message}`, "error");
      return error.message;
    }
  };

  const addDreamAvatarsPromptsData = async (token: string, payload: any) => {
    try {
      const url =
        "https://us-central1-mas-lensify.cloudfunctions.net/api/dream-avatars/add-prompts-data";
      await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      showSnackbar("Operation successful", "success");
    } catch (error: any) {
      console.log("[Error] add dream avatars data", error.message);
      showSnackbar(`Operation Failed: ${error.message}`, "error");
      return error.message;
    }
  };

  return {
    checkUserExists,
    addNewUser,
    addDreamAvatarsStylesData,
    addDreamAvatarsPromptsData,
  };
};

export default useGoogleFirestore;
