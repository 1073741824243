import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./screens/Login";
import Home from "./screens/Home";
import { auth } from "./hooks/useGoogleSignIn";
import { useAuthState } from "react-firebase-hooks/auth";
import Header from "./components/Header";
import SideDrawer from "./components/SideDrawer";
import { Box } from "@mui/material";
import AddData from "./screens/AddData";
import { isMobileDevice } from "./utils/browser.util";

const App = () => {
  const isMobile = isMobileDevice();

  const [user] = useAuthState(auth);
  // const isRoot = window.location.pathname == "/";

  return (
    <Router>
      {user && <Header profile title="" />}
      {!isMobile && user ? <SideDrawer /> : null}
      <Box
        width={user && !isMobile ? "calc(100% - 250px)" : "100%"}
        sx={{ position: "relative", left: user && !isMobile ? 250 : 0 }}
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home user={user} />} />
          <Route path="/add-data" element={<AddData user={user} />} />
        </Routes>
      </Box>
    </Router>
  );
};

export default App;
