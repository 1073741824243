import { useDispatch, useSelector } from "react-redux";
import { setShowSnackbar } from "../context/slices/app.slice";
import { memoizedSelectApp } from "../context/selectors/app.selector";

const useSnackbar = () => {
  const dispatch = useDispatch();

  const { snackbarMessage, snackbarSeverity } = useSelector(memoizedSelectApp);

  const showSnackbar = (message: string, severity: any) => {
    const payload = {
      showSnackbar: true,
      snackbarMessage: message,
      snackbarSeverity: severity,
    };
    dispatch(setShowSnackbar(payload));
  };

  const closeSnackbar = () => {
    const payload = {
      showSnackbar: false,
      snackbarMessage: snackbarMessage,
      snackbarSeverity,
    };
    dispatch(setShowSnackbar(payload));
  };

  return { showSnackbar, closeSnackbar };
};

export default useSnackbar;
