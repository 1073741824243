export const GIRLS_STYLES_DATA = {
  styles: [
    {
      name: "Batwoman",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/superhero/batwoman.webp",
      prompt: "prompt1",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Iron Woman",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/superhero/ironwoman.webp",
      prompt: "prompt2",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Scarlet Witch",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/superhero/scarletwitch.webp",
      prompt: "prompt3",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Spider Woman",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/superhero/spiderwoman.webp",
      prompt: "prompt4",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Superwoman",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/superhero/superwoman.webp",
      prompt: "prompt5",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Wonder Woman",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/superhero/wonderwoman.webp",
      prompt: "prompt6",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Purple Floral Dress",
      type: "princess",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/princess/purple-floral-frock.webp",
      prompt: "prompt7",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Purple Frock",
      type: "princess",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/princess/purple-frock.webp",
      prompt: "prompt8",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Skyblue Frock",
      type: "princess",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/princess/skyblue-frock.webp",
      prompt: "prompt9",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "White Frock",
      type: "princess",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/princess/white-floral-frock.webp",
      prompt: "prompt10",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "White Dress",
      type: "princess",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/princess/white-frock.webp",
      prompt: "prompt11",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Indian Bridal Wear",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/wedding/indian-bridal-wear.webp",
      prompt: "prompt12",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Western Bridal Wear",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/wedding/western-bridal-wear.webp",
      prompt: "prompt13",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Lord Devi",
      type: "religious",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/religious/hindu-dress.webp",
      prompt: "prompt14",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Lord Devi",
      type: "religious",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/religious/lord-devi.webp",
      prompt: "prompt15",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Indian Dress",
      type: "traditional",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/traditional/hindu-dress.webp",
      prompt: "prompt16",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Indian Ghagra",
      type: "traditional",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/traditional/indian-ghagra.webp",
      prompt: "prompt17",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Islamic Dress",
      type: "traditional",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/traditional/islamic-dress.webp",
      prompt: "prompt18",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Islamic Dress",
      type: "traditional",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/traditional/islamic-dress-1.webp",
      prompt: "prompt19",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Black Jumpsuit",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/modern/black-jumpsuit.webp",
      prompt: "prompt20",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Retro Jumpsuit",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/modern/modern-retro-jumpsuit.webp",
      prompt: "prompt21",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Pink Punch",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/modern/pink-punch.webp",
      prompt: "prompt22",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Pop Jacket",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/modern/pop-jacket.webp",
      prompt: "prompt23",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "White Suit",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/girls/modern/white-suit.webp",
      prompt: "prompt24",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
  ],
};
