import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedInUser: {
    uid: "",
    name: "",
    email: "",
    photo: "",
    idToken: "",
    role: "",
  },
  selectedStyle: null,
  avatarsFor: "",
  ordersList: [],
  showSnackbar: false,
  snackbarMessage: "",
  snackbarSeverity: "info",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.loggedInUser.uid = action.payload.uid;
      state.loggedInUser.name = action.payload.displayName;
      state.loggedInUser.email = action.payload.email;
      state.loggedInUser.photo = action.payload.photoURL;
      state.loggedInUser.idToken = action.payload.accessToken;
      state.loggedInUser.role = action.payload.role;
    },
    setSelectedStyle: (state, action) => {
      state.selectedStyle = action.payload;
    },
    setAvatarsFor: (state, action) => {
      state.avatarsFor = action.payload;
    },
    setOrdersList: (state, action) => {
      state.ordersList = action.payload;
    },
    setShowSnackbar: (state, action) => {
      state.showSnackbar = action.payload.showSnackbar;
      state.snackbarMessage = action.payload.snackbarMessage;
      state.snackbarSeverity = action.payload.snackbarSeverity;
    },
  },
});

export const {
  setUser,
  setSelectedStyle,
  setAvatarsFor,
  setOrdersList,
  setShowSnackbar,
} = appSlice.actions;
export default appSlice.reducer;
