import axios from "axios";
import useSnackbar from "./useSnackbar";
import useFetchOrdersList from "./useFetchOrdersList";

const useApproveDisapproveOrder = () => {
  const { showSnackbar } = useSnackbar();

  const { updateOrderList } = useFetchOrdersList();

  const approveOrder = async (token: string, payload: any) => {
    try {
      const url =
        "https://us-central1-mas-lensify.cloudfunctions.net/api/dream-avatars/approve";
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      updateOrderList(payload?.txnId, "approve");
      showSnackbar("Operation successful", "success");
      return response.data;
    } catch (error: any) {
      console.log("[Error] approve order", error.message);
      showSnackbar(`Operation Failed: ${error.message}`, "error");
      return error.message;
    }
  };

  const disapproveOrder = async (token: string, payload: any) => {
    try {
      const url =
        "https://us-central1-mas-lensify.cloudfunctions.net/api/dream-avatars/disapprove";
      await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      updateOrderList(payload?.txnId, "disapprove");
      showSnackbar("Operation successful", "success");
    } catch (error: any) {
      console.log("[Error] disapprove order", error.message);
      showSnackbar(`Operation Failed: ${error.message}`, "error");
      return error.message;
    }
  };

  const markDoneOrder = async (token: string, payload: any) => {
    try {
      const url =
        "https://us-central1-mas-lensify.cloudfunctions.net/api/dream-avatars/mark-done-order";
      await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      updateOrderList(payload?.txnId, "done");
      showSnackbar("Operation successful", "success");
    } catch (error: any) {
      console.log("[Error] mark done order", error.message);
      showSnackbar(`Operation Failed: ${error.message}`, "error");
      return error.message;
    }
  };

  return { approveOrder, disapproveOrder, markDoneOrder };
};

export default useApproveDisapproveOrder;
