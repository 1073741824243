import React, { FC, useState } from "react";
import JSZip from "jszip";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import useSnackbar from "../hooks/useSnackbar";
import Theme from "../constants/Theme";

interface IImage {
  name: string;
  url: string;
}

interface IImagePreviewModalProps {
  zipUrl: string;
  jobOutputImages: [];
}

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: "100%",
    height: "40px",
    cursor: "pointer",
    marginTop: 14,
    borderRadius: 4,
  },
}));

const ImagePreviewModal: FC<IImagePreviewModalProps> = ({
  zipUrl,
  jobOutputImages,
}) => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const [images, setImages] = useState<IImage[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<IImage | null>(null);

  const fetchAndReadZip = async () => {
    try {
      const response = await fetch(zipUrl);
      const data = await response.blob();

      const zip = await JSZip.loadAsync(data);

      const imageFiles: { name: string; data: Promise<Blob> }[] = [];
      zip.forEach((relativePath, zipEntry) => {
        if (!zipEntry.dir && zipEntry.name.match(/\.(jpg|jpeg|png)$/i)) {
          imageFiles.push({
            name: zipEntry.name,
            data: zipEntry.async("blob"),
          });
        }
      });

      const imagePreviews = await Promise.all(
        imageFiles.map(async (imageFile) => {
          const blob = await imageFile.data;
          const imageUrl = URL.createObjectURL(blob);
          return { name: imageFile.name, url: imageUrl };
        })
      );
      setImages(imagePreviews);
    } catch (error: any) {
      console.error("Error reading zip file:", error.message);
      showSnackbar(`Error reading zip file: ${error.message}`, "error");
    }
  };

  const handleOpenDialog = (image: IImage) => {
    setSelectedImage(image);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImage(null);
  };

  const transformImageURLs = (imageURLsArray: []) => {
    return imageURLsArray.map((item) => {
      const key = Object.keys(item)[0]; // Assuming there's only one key in each object
      return {
        name: key,
        url: item[key],
      };
    });
  };

  const handleViewImages = () => {
    if (zipUrl !== "") {
      fetchAndReadZip();
    } else {
      const transformedArray = transformImageURLs(jobOutputImages);
      setImages(transformedArray);
    }
  };

  return (
    <Container>
      <Button
        size="small"
        variant="contained"
        sx={{
          fontWeight: "bold",
          bgcolor: Theme.COLORS.IRIS_BLUE,
          color: Theme.COLORS.TEXT_PRIMARY_COLOR,
        }}
        onClick={() => handleViewImages()}
      >
        View Images
      </Button>
      <Grid container spacing={2}>
        {images.map((image) => (
          <Grid item xs={6} sm={2} key={image.name}>
            <img
              src={image.url}
              alt={image.name}
              className={classes.thumbnail}
              onClick={() => handleOpenDialog(image)}
            />
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedImage && selectedImage.name}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Image src={selectedImage?.url} alt={selectedImage?.name} />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ImagePreviewModal;

const Container = styled.div`
  padding: 8px;
  box-sizing: border-box;
  height: 100px;
  overflow: auto;
  ::-webkit-scrollbar: {
    display: none;
  }
  scrollbar-width: none;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin-right: 8px;
  cursor: pointer;
`;
