export const WOMEN_STYLES_DATA = {
  styles: [
    {
      name: "Pink Floral Dress",
      type: "princess",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/princess/pink-floral-dress.webp",
      prompt: "prompt1",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Purple Floral Dress",
      type: "princess",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/princess/purple-floral-dress.webp",
      prompt: "prompt2",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Skyblue Dress",
      type: "princess",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/princess/skyblue-dress.webp",
      prompt: "prompt3",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Indian Bridal Wear",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/wedding/indian-bridal-wear.webp",
      prompt: "prompt4",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Western Bridal Wear",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/wedding/western-bridal-wear.webp",
      prompt: "prompt5",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Indian Dress",
      type: "traditional",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/traditional/dress.webp",
      prompt: "prompt6",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Korean Dress",
      type: "traditional",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/traditional/korean.webp",
      prompt: "prompt7",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Salwar Suit",
      type: "traditional",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/traditional/salwar-suit.webp",
      prompt: "prompt8",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Saree",
      type: "traditional",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/traditional/saree.webp",
      prompt: "prompt9",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Leather Jacket",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/modern/leather-jacket.webp",
      prompt: "prompt10",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Pop Jacket",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/modern/pop-jacket.webp",
      prompt: "prompt11",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Punk",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/modern/punk.webp",
      prompt: "prompt12",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Red Suit",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/modern/red-pallazo.webp",
      prompt: "prompt13",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Short Jeans",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/modern/shorts-jeans.webp",
      prompt: "prompt14",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Smart Casuals",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/modern/smart-casuals.webp",
      prompt: "prompt15",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Smart Casuals",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/modern/smart-casuals-1.webp",
      prompt: "prompt16",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Smart Formals",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/modern/smart-formals.webp",
      prompt: "prompt17",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Sporty",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/modern/sporty.webp",
      prompt: "prompt18",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "T-shirt Jeans",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/modern/tshirt-jeans.webp",
      prompt: "prompt19",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "White Suit",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/women/modern/white-suit.webp",
      prompt: "prompt20",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
  ],
};
