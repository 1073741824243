import { FC } from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import LogoutIcon from "@mui/icons-material/Logout";

import useGoogleSignIn from "../hooks/useGoogleSignIn";
import { memoizedSelectApp } from "../context/selectors/app.selector";

import Theme from "../constants/Theme";

interface IHeaderProps {
  title: string;
  profile: boolean;
}

const Header: FC<IHeaderProps> = ({ profile, title }) => {
  const { loggedInUser } = useSelector(memoizedSelectApp);

  const { handleLogout } = useGoogleSignIn();

  return (
    <AppBar sx={{ position: "relative", bgcolor: Theme.COLORS.BLACK_RUSSIAN }}>
      <Toolbar>
        <ModalTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <HeaderRightSection>
              {profile && (
                <>
                  <ProfileImage src={loggedInUser?.photo} />
                  <div>
                    <HeaderText>Hello</HeaderText>
                    <HeaderSubtext>{loggedInUser?.name}</HeaderSubtext>
                  </div>
                </>
              )}
              {title}
            </HeaderRightSection>
            <HeaderRightSection>
              <LogoutIcon
                sx={{
                  width: 24,
                  height: 24,
                  color: Theme.COLORS.TEXT_SECONDARY_COLOR,
                  cursor: "pointer",
                }}
                onClick={handleLogout}
              />
            </HeaderRightSection>
          </Box>
        </ModalTitle>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

const ModalTitle = styled.p`
  flex: 1;
  font-size: 17px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 4px;
  border: 1.5px solid ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const HeaderText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const HeaderSubtext = styled.p`
  margin: 2px 0;
  font-size: 12px;
  font-weight: bold;
  color: ${Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const HeaderRightSection = styled.div`
  display: flex;
  align-items: center;
`;
