import { Box, Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import Theme from "../constants/Theme";
import useApproveDisapproveOrder from "./useApproveDisapproveOrder";
import { useSelector } from "react-redux";
import { memoizedSelectApp } from "../context/selectors/app.selector";
import styled from "@emotion/styled";

type TextProps = {
  bold?: boolean;
  color?: string;
};

const useRenderOrderStatus = () => {
  const { loggedInUser } = useSelector(memoizedSelectApp);

  const { approveOrder, disapproveOrder, markDoneOrder } =
    useApproveDisapproveOrder();

  const getClassPrompt = (avatarsFor: string): string => {
    let classPrompt = "";
    if (avatarsFor === "men") {
      return "man";
    }
    if (avatarsFor === "women") {
      return "woman";
    }
    return classPrompt;
  };

  const handleApprove = (order: any, email: string) => {
    const instancePrompt = uuidv4();
    const { avatarsFor, sdModel, s3FileUrl, transactionId } = order;
    const classPrompt = getClassPrompt(avatarsFor);
    const payload = {
      trainInput: {
        data_url: s3FileUrl,
        instance_prompt: instancePrompt,
        class_prompt: classPrompt,
        output_name: `${instancePrompt}_${classPrompt}`,
        avatarsFor,
        sd_model: sdModel,
      },
      email,
      txnId: transactionId,
      moderator: loggedInUser?.email,
    };
    approveOrder(loggedInUser?.idToken, payload);
  };

  const handleDisapprove = (order: any, email: string) => {
    const payload = {
      email,
      txnId: order?.transactionId,
      moderator: loggedInUser?.email,
    };
    disapproveOrder(loggedInUser?.idToken, payload);
  };

  const handleMarkDone = (email: string, txnId: string) => {
    const payload = {
      email,
      txnId,
      moderator: loggedInUser?.email,
    };
    markDoneOrder(loggedInUser?.idToken, payload);
  };

  const renderOrderAction = (order: any, email: string) => {
    if (order?.jobStatus === "REVIEWING") {
      return (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            fullWidth
            variant="contained"
            sx={{
              marginTop: 1,
              mr: 1,
              fontWeight: "bold",
              bgcolor: Theme.COLORS.IRIS_BLUE,
              color: Theme.COLORS.TEXT_PRIMARY_COLOR,
            }}
            onClick={() => handleApprove(order, email)}
          >
            Approve
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              marginTop: 1,
              fontWeight: "bold",
              bgcolor: Theme.COLORS.IRIS_BLUE,
              color: Theme.COLORS.TEXT_PRIMARY_COLOR,
            }}
            onClick={() => handleDisapprove(order, email)}
          >
            Disapprove
          </Button>
        </Box>
      );
    }
    if (order?.jobStatus === "PROCESSING") {
      return (
        <Text
          bold
          color={Theme.COLORS.WARNING}
        >{`Order Status: Processing`}</Text>
      );
    }
    if (order?.jobStatus === "COMPLETED") {
      return (
        <Button
          fullWidth
          variant="contained"
          sx={{
            fontWeight: "bold",
            bgcolor: Theme.COLORS.IRIS_BLUE,
            color: Theme.COLORS.TEXT_PRIMARY_COLOR,
          }}
          onClick={() => handleMarkDone(email, order?.transactionId)}
        >
          Mark Done
        </Button>
      );
    }
    if (order?.jobStatus === "DONE") {
      return (
        <Text bold color={Theme.COLORS.SUCCESS}>{`Order Status: Done`}</Text>
      );
    }
  };

  const renderOrderStatus = (order: any, email: string) => {
    return renderOrderAction(order, email);
  };

  return { renderOrderStatus };
};

export default useRenderOrderStatus;

const Text = styled.div<TextProps>`
  margin: 0;
  font-size: 15px;
  text-align: center;
  font-weight: ${(props) => (props.bold ? "bold" : 500)};
  color: ${(props) => props.color || Theme.COLORS.DARK_GRAY};
`;
