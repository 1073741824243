export const WOMEN_PROMPTS_DATA = {
  prompt1: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, 1girl, portrait photograph of {instance_prompt}, fit body, wearing a stylish and expensive Princess Knee Length Flower Girl Dress, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 1883452560,
    steps: 40,
    width: 1024,
  },
  prompt2: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, 1girl, portrait photograph of {instance_prompt}, fit body, wearing a Floral Ball Gown | Lavender Princess Dress | Beautiful, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 609774813,
    steps: 40,
    width: 1024,
  },
  prompt3: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, 1girl, {instance_prompt}, fit body, wearing a stylish and expensive Princess Knee Length Flower Girl Dress, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 65064023,
    steps: 40,
    width: 1024,
  },
  prompt4: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, 1girl, {instance_prompt}, fit body, wearing a stylish and expensive indian bridal wear, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2113652925,
    steps: 40,
    width: 1024,
  },
  prompt5: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, 1girl, {instance_prompt}, fit body, wearing a stylish and expensive bridal wear, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2388813638,
    steps: 40,
    width: 1024,
  },
  prompt6: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, posing for a picture, portrait photograph of {instance_prompt}, fit body, stylish pakistani dress, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 955902014,
    steps: 40,
    width: 1024,
  },
  prompt7: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, posing for a picture, portrait photograph of {instance_prompt}, fit body, stylish korean dress, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 1778103373,
    steps: 40,
    width: 1024,
  },
  prompt8: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, 1girl, {instance_prompt}, fit body, wearing a stylish and expensive indian salwar suit, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2909097866,
    steps: 40,
    width: 1024,
  },
  prompt9: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, posing for a picture, portrait photograph of {instance_prompt}, fit body, saree, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2171951736,
    steps: 40,
    width: 1024,
  },
  prompt10: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, posing for a picture, portrait photograph of {instance_prompt}, fit body, stylish biker jacket and leather pants, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 966480971,
    steps: 40,
    width: 1024,
  },
  prompt11: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "High-resolution, full-body photograph of ({instance_prompt}) suitable for a popular Instagram post. (Fashion-forward:1.3), (urban backdrop:1.4), capturing the contemporary and vibrant energy of Instagram's aesthetics.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3329527353,
    steps: 40,
    width: 1024,
  },
  prompt12: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, posing for a picture, portrait photograph of {instance_prompt}, fit body, punk style, sunglasses, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 182886736,
    steps: 40,
    width: 1024,
  },
  prompt13: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, 1girl, {instance_prompt}, fit body, wearing a Ruffle Trim Belted Jumpsuit, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 1909135220,
    steps: 40,
    width: 1024,
  },
  prompt14: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, posing for a picture, portrait photograph of {instance_prompt}, fit body, pop style, sunglasses, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 4198807416,
    steps: 40,
    width: 1024,
  },
  prompt15: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1536,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, posing for a picture, portrait photograph of {instance_prompt}, fit body, pop style, sunglasses, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2567562794,
    steps: 40,
    width: 1024,
  },
  prompt16: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1536,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, portrait photograph of {instance_prompt}, fit body, pop style, sunglasses, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3075559957,
    steps: 40,
    width: 1024,
  },
  prompt17: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, 1girl, {instance_prompt}, fit body, wearing a stylish and expensive formal shirt and pant, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2120064974,
    steps: 40,
    width: 1024,
  },
  prompt18: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, posing for a picture, portrait photograph of {instance_prompt}, fit body, stylish streetwear, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 1142760145,
    steps: 40,
    width: 1024,
  },
  prompt19: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, 1girl, {instance_prompt}, fit body, wearing a stylish and expensive smart casuals, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 1660587287,
    steps: 40,
    width: 1024,
  },
  prompt20: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt: "",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), portrait photo of ({instance_prompt}:1.1) wearing an expensive White suit, white background, fit.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
};
