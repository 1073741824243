import styled from "@emotion/styled";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Snackbar from "../components/Snackbar";
import InputForm from "../components/InputForm";
import CircularLoader from "../components/CircularLoader";

import useGoogleSignIn from "../hooks/useGoogleSignIn";

import Theme from "../constants/Theme";

const Login = () => {
  const navigate = useNavigate();

  const {
    user,
    loading,
    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
    handleCloseSnackbar,
    signInWithGooglePopup,
  } = useGoogleSignIn();

  const googleSignInHandler = () => {
    signInWithGooglePopup();
  };

  useEffect(() => {
    if (user) {
      navigate("/home");
    }
  }, [user, navigate]);

  return (
    <Container>
      {loading ? (
        <CircularLoader />
      ) : (
        <InputForm googleSignInHandler={googleSignInHandler} />
      )}
      <Snackbar
        open={showSnackbar}
        vertical="bottom"
        horizontal="center"
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleCloseSnackbar}
      />
    </Container>
  );
};

export default Login;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to bottom right,
    ${Theme.COLORS.BG_COLOR},
    ${Theme.COLORS.BLACK_RUSSIAN}
  );
`;
