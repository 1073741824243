import axios from "axios";

const useCheckAdminAccess = () => {
  const checkAdminAccess = async (token: string, payload: any) => {
    try {
      const url =
        "https://us-central1-mas-lensify.cloudfunctions.net/api/user/check-admin-access";
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log("[Error] user exists", error.message);
      return error.message;
    }
  };

  return { checkAdminAccess };
};

export default useCheckAdminAccess;
