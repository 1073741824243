import { FC } from "react";
import styled from "@emotion/styled";
import GoogleIcon from "@mui/icons-material/Google";
import { Button } from "@mui/material";

import Theme from "../constants/Theme";

const LOGO_URL =
  "https://lensify.masinfionex.com/assets/images/lensify-round-icon.webp";

interface IInputFormProps {
  googleSignInHandler?: () => void;
}

const InputForm: FC<IInputFormProps> = ({ googleSignInHandler }) => {
  return (
    <Container>
      <Logo src={LOGO_URL} alt="logo" />
      <Text>Lensify</Text>
      <SubText>Agent Panel</SubText>
      <Button
        fullWidth
        variant="contained"
        startIcon={<GoogleIcon sx={{ width: 20, height: 20 }} />}
        sx={{
          mt: 6,
          mb: 1,
          py: 2,
          px: 12,
          fontWeight: "bold",
          fontSize: "1.9vh",
          borderRadius: 2,
          background: Theme.COLORS.IRIS_BLUE,
          color: Theme.COLORS.TEXT_PRIMARY_COLOR,
        }}
        onClick={googleSignInHandler}
      >
        Google Sign In
      </Button>
    </Container>
  );
};

export default InputForm;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
  border: 2px solid ${Theme.COLORS.WHITE};
`;

const Logo = styled.img`
  width: 102px;
  height: 104px;
  margin-bottom: 0;
  margin-top: 16px;
`;

const Text = styled.p`
  color: ${Theme.COLORS.WHITE};
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
`;

const SubText = styled.p`
  color: ${Theme.COLORS.WHITE};
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  opacity: 0.9;
  margin: 8px 0 0 0;
`;
