import { FC } from "react";
import styled from "@emotion/styled";
import { Box, Button, CardMedia } from "@mui/material";

import useRenderOrderStatus from "../hooks/useRenderOrderStatus";

import Theme from "../constants/Theme";
import { formattedDate } from "../utils/dateTime.util";
import ImagePreviewModal from "./ImagePreviewModal";
import { isMobileDevice } from "../utils/browser.util";
import useApproveDisapproveOrder from "../hooks/useApproveDisapproveOrder";

type TextProps = {
  bold?: boolean;
  color?: string;
};

interface IOrderCardProps {
  email: string;
  order: any;
  loggedInUser: any;
}

const isMobile = isMobileDevice();

const OrderCard: FC<IOrderCardProps> = ({ email, order, loggedInUser }) => {
  const { renderOrderStatus } = useRenderOrderStatus();
  const { markDoneOrder } = useApproveDisapproveOrder();

  const renderOrderHeader = () => {
    let color = Theme.COLORS.SUCCESS;
    const { jobStatus, transactionId } = order;
    if (jobStatus === "UPLOAD_PHOTOS") {
      color = Theme.COLORS.INFO;
    }
    if (jobStatus === "REVIEWING") {
      color = Theme.COLORS.INFO;
    }
    if (jobStatus === "PROCESSING") {
      color = Theme.COLORS.WARNING;
    }
    if (jobStatus === "ERROR") {
      color = Theme.COLORS.ERROR;
    }
    return (
      <OrderNumConatiner
        color={color}
      >{`Txn No. ${transactionId}`}</OrderNumConatiner>
    );
  };

  return (
    <Box
      sx={{
        mr: isMobile ? 0 : 2,
        width: isMobile ? "100%" : 401,
        // height: 432,
        bgcolor: Theme.COLORS.ONYX,
        mb: 2,
        borderRadius: 2,
        boxSizing: "border-box",
        overflow: "hidden",
        boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.3);",
        display: "flex",
        flexDirection: "column",
        border: `2px solid ${Theme.COLORS.ONYX}`,
      }}
    >
      {renderOrderHeader()}
      <Box px={2} mt={1}>
        <Text>{`Customer: ${email}`}</Text>
      </Box>
      <Box
        sx={{
          py: 1,
          px: 2,
          my: 2,
        }}
      >
        <Text>Selected Styles Images</Text>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-evenly",
          }}
        >
          {order?.selectedStyles?.map((item: any) => (
            <CardMedia
              key={item?.image}
              sx={{
                mt: 2,
                height: 40,
                width: 40,
                borderRadius: 2,
                flexShrink: 0,
                mr: 2,
                padding: 1,
                border: `2px solid ${Theme.COLORS.BLACK_RUSSIAN}`,
                alignSelf: "flex-start",
              }}
              image={item?.image}
            />
          ))}
        </Box>
        <Box
          p={1}
          mt={2}
          height={200}
          sx={{
            alignSelf: "flex-start",
            flex: 1,
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: " repeat(5, 1fr)",
            gap: "12px",
          }}
        >
          <Text>Selected Styles</Text>
          <Box
            sx={{
              display: "grid",
              gridTemplate: "1,1",
              gap: "6px",
            }}
          >
            {order?.selectedStyles.map((item: any) => (
              <Text key={item?.name}>{`${item?.name}`}</Text>
            ))}
          </Box>
          <Text>Price</Text>
          <Text>&#x20B9;{order?.amount / 100}/-</Text>
          <Text>Payment</Text>
          <Text>{order?.responseCode}</Text>
          <Text>Date</Text>
          <Text>{formattedDate(order?.date)}</Text>
          <Text>Job Status</Text>
          <Text>{order?.jobStatus}</Text>
        </Box>
      </Box>
      {order?.moderator ? (
        <Box px={2} mt={5} mb={1}>
          <Text>{`Moderator: ${order?.moderator}`}</Text>
        </Box>
      ) : null}
      {/* <Divider
        color={Theme.COLORS.BLACK_RUSSIAN}
        sx={{ height: "1px", mt: 1 }}
      /> */}
      {order?.jobStatus === "REVIEWING" ? (
        <Box p={1} mt={2} bgcolor={Theme.COLORS.ONYX}>
          <ImagePreviewModal zipUrl={order?.s3FileUrl} jobOutputImages={[]} />
        </Box>
      ) : null}
      {order?.jobStatus === "COMPLETED" ? (
        <Box p={1} mt={2} bgcolor={Theme.COLORS.ONYX}>
          <ImagePreviewModal zipUrl={order?.s3FileUrl} jobOutputImages={[]} />
          <ImagePreviewModal
            zipUrl=""
            jobOutputImages={order?.jobOutputImages}
          />
        </Box>
      ) : null}
      {/* <Divider
        color={Theme.COLORS.BLACK_RUSSIAN}
        sx={{ height: "1px", mt: 1 }}
      /> */}
      <Box
        sx={{
          p: 1,
          bgcolor: Theme.COLORS.BLACK_RUSSIAN,
        }}
      >
        {renderOrderStatus(order, email)}
      </Box>
    </Box>
  );
};

export default OrderCard;

const OrderNumConatiner = styled.div<TextProps>`
  background: ${Theme.COLORS.BLACK_RUSSIAN};
  padding: 16px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.color || Theme.COLORS.TEXT_SECONDARY_COLOR};
`;

const Text = styled.div<TextProps>`
  margin: 0;
  font-size: 15px;
  font-weight: ${(props) => (props.bold ? "bold" : 500)};
  color: ${(props) => props.color || Theme.COLORS.TEXT_SECONDARY_COLOR};
`;
