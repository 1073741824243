import { createSelector } from "reselect";
import { RootState } from "../store";

const selectLoggedInUser = (state: RootState) => state.app.loggedInUser;
const selectedStyle = (state: RootState) => state.app.selectedStyle;
const selectedAvatarsFor = (state: RootState) => state.app.avatarsFor;
const selectOrdersList = (state: RootState) => state.app.ordersList;
const selectShowSnackbar = (state: RootState) => state.app.showSnackbar;
const selectSnackbarMessage = (state: RootState) => state.app.snackbarMessage;
const selectSnackbarSeverity = (state: RootState) => state.app.snackbarSeverity;

export const memoizedSelectApp = createSelector(
  [
    selectLoggedInUser,
    selectedStyle,
    selectedAvatarsFor,
    selectOrdersList,
    selectShowSnackbar,
    selectSnackbarMessage,
    selectSnackbarSeverity,
  ],
  (
    loggedInUser,
    selectedStyle,
    avatarsFor,
    ordersList,
    showSnackbar,
    snackbarMessage,
    snackbarSeverity
  ) => ({
    loggedInUser,
    selectedStyle,
    avatarsFor,
    ordersList,
    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
  })
);
