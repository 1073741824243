import { useState } from "react";
import { initializeApp } from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";

import { firebaseConfig } from "../constants/firebaseConfig";

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account ",
});

const useGoogleSignIn = () => {
  const [user, loading, error] = useAuthState(auth);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "error" | "info" | "success" | "warning"
  >("success");

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const signInWithGooglePopup = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error: any) {
      console.log("[Error] signin with google popup", error.message);
    }
  };

  const handleLogout = () => {
    try {
      signOut(auth);
    } catch (error: any) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message);
      localStorage.removeItem("emailForSignin");
      console.log("[Error] sign out", error.message);
    }
  };

  return {
    user,
    loading,
    error,
    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
    handleCloseSnackbar,
    signInWithGooglePopup,
    handleLogout,
  };
};

export default useGoogleSignIn;
