export const GIRLS_PROMPTS_DATA = {
  prompt1: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing batman clothes, fit, night street lighting, city background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt2: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing an ironman suit, street lighting, city background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt3: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing scarlet witch suit, city street background, dark night, fit.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt4: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing spiderman clothes, fit, street lighting, city background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt5: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing superman clothes, tech buildings in background, rooftop, fit, blur background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt6: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing wonder woman suit, street lighting, thunder background, dark night, fit.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt7: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, Floral Ball Gown | Lavender Princess Dress | Beautiful, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3796251557,
    steps: 40,
    width: 1024,
  },
  prompt8: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, Floral Ball Gown | Lavender Princess Dress | Beautiful, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 49240655,
    steps: 40,
    width: 1024,
  },
  prompt9: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, Princess Knee Length Flower Girl Dress Cute Prom Dress Tulle with Paillette, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 4106587679,
    steps: 40,
    width: 1024,
  },
  prompt10: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, Princess Knee Length Flower Girl Dress Christmas Cute Prom Dress Tulle with Paillette, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2193668116,
    steps: 40,
    width: 1024,
  },
  prompt11: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, Princess Knee Length Flower Girl Dress Christmas Cute Prom Dress Tulle with Paillette, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3100420446,
    steps: 40,
    width: 1024,
  },
  prompt12: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, expensive indian wedding dress, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 1899922741,
    steps: 40,
    width: 1024,
  },
  prompt13: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, expensive wedding dress, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2235488227,
    steps: 40,
    width: 1024,
  },
  prompt14: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, full length photo of ({instance_prompt}:1.1) wearing an orange lord devi attire, lord devi accessories, holding gadha, ashram in background, blur background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt15: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, full length photo of ({instance_prompt}:1.1) wearing goddess Parvati full body attire, goddess Parvati accessories, holding gadha, ashram in background, blur background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt16: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, expensive hindu dress, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3165498704,
    steps: 40,
    width: 1024,
  },
  prompt17: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, expensive indian dress, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 517585779,
    steps: 40,
    width: 1024,
  },
  prompt18: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, expensive islamic dress, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2600123033,
    steps: 40,
    width: 1024,
  },
  prompt19: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, full length photo of ({instance_prompt}:1.1) wearing an dubai attire, dubai fashion accessories, dubai in background, blur background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3165498704,
    steps: 40,
    width: 1024,
  },
  prompt20: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, FANZEY Girls Mesh Yoke Flare Leg Jumpsuit- Tween Girls Jumpsuits at SHEIN, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 616950865,
    steps: 40,
    width: 1024,
  },
  prompt21: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, the 70's with this fun fashion bell bottom jumpsuit, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 868906472,
    steps: 40,
    width: 1024,
  },
  prompt22: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, Festive And Party Lycra Onion Pink.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 155851414,
    steps: 40,
    width: 1024,
  },
  prompt23: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "High-resolution, full-body photograph of {instance_prompt} suitable for a popular Instagram post. (Fashion-forward:1.3), (urban backdrop:1.4), capturing the contemporary and vibrant energy of Instagram's aesthetics.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3165498704,
    steps: 40,
    width: 1024,
  },
  prompt24: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing an expensive White suit, white background, fit.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
};
