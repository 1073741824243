export const MEN_STYLES_DATA = {
  styles: [
    {
      name: "Army Cargo",
      type: "hunk",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/hunk/army-cargo.webp",
      prompt: "prompt1",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Gym Wear",
      type: "hunk",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/hunk/gym-wear.webp",
      prompt: "prompt2",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Leather Jacket",
      type: "hunk",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/hunk/leather-jacket.webp",
      prompt: "prompt3",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Sporty",
      type: "hunk",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/hunk/sporty.webp",
      prompt: "prompt4",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Streetwear",
      type: "hunk",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/hunk/street-wear.webp",
      prompt: "prompt5",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Sherwani",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/wedding/sherwani-1.webp",
      prompt: "prompt6",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Sherwani",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/wedding/sherwani-2.webp",
      prompt: "prompt7",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Suit",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/wedding/wedding-suit-1.webp",
      prompt: "prompt8",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Suit",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/wedding/wedding-suit-2.webp",
      prompt: "prompt9",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Cardigan",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/modern/cardigan.webp",
      prompt: "prompt10",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Formals",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/modern/formals.webp",
      prompt: "prompt11",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Pop Jacket",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/modern/pop-jacket.webp",
      prompt: "prompt12",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Pop Hoodie",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/modern/pop-hoodie.webp",
      prompt: "prompt13",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Pop Suit",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/modern/pop-suit.webp",
      prompt: "prompt14",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Pop Tracks",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/modern/pop-tracks.webp",
      prompt: "prompt15",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Smart Casual",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/modern/smart-casual.webp",
      prompt: "prompt16",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Suit",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/modern/suit.webp",
      prompt: "prompt17",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "T-Shirt Jeans",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/modern/tshirt-jeans.webp",
      prompt: "prompt18",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "White Suit",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/men/modern/white-suit.webp",
      prompt: "prompt19",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
  ],
};
