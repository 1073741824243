export const BOYS_STYLES_DATA = {
  styles: [
    {
      name: "Batman",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/superhero/batman.webp",
      prompt: "prompt1",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Black Panther",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/superhero/black-panther.webp",
      prompt: "prompt2",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Captain America",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/superhero/captain-america.webp",
      prompt: "prompt3",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Iron Man",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/superhero/ironman.webp",
      prompt: "prompt4",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Spider Man",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/superhero/spiderman.webp",
      prompt: "prompt5",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Superman",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/superhero/superman.webp",
      prompt: "prompt6",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Thor",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/superhero/thor.webp",
      prompt: "prompt7",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Wolverine",
      type: "superhero",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/superhero/wolverine.webp",
      prompt: "prompt8",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Jhubba Pyjama",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/wedding/jhubba-pyjama-1.webp",
      prompt: "prompt9",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Sherwani",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/wedding/sherwani-1.webp",
      prompt: "prompt10",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Sherwani",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/wedding/sherwani-2.webp",
      prompt: "prompt11",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Suit",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/wedding/wedding-suit-1.webp",
      prompt: "prompt12",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Suit",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/wedding/wedding-suit-2.webp",
      prompt: "prompt13",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Suit",
      type: "wedding",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/wedding/wedding-suit-3.webp",
      prompt: "prompt14",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Hanuman",
      type: "religious",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/religious/hanuman.webp",
      prompt: "prompt15",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Islamic Dress",
      type: "religious",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/religious/islamic-dress-1.webp",
      prompt: "prompt16",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Krishna",
      type: "religious",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/religious/krishna.webp",
      prompt: "prompt17",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Ram",
      type: "religious",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/religious/ram.webp",
      prompt: "prompt18",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Ashram",
      type: "traditional",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/traditional/ashram.webp",
      prompt: "prompt19",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Jhubba Pyjama",
      type: "traditional",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/traditional/jhubba-pyjama.webp",
      prompt: "prompt20",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Hindu Dress",
      type: "traditional",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/traditional/hindu-1.webp",
      prompt: "prompt21",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Hindu Dress",
      type: "traditional",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/traditional/hindu-2.webp",
      prompt: "prompt22",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Army Cargo",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/modern/army-cargo.webp",
      prompt: "prompt23",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Black Cargo",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/modern/black-cargo.webp",
      prompt: "prompt24",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Korean",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/modern/korean.webp",
      prompt: "prompt25",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Pop Jacket",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/modern/pop-jacket.webp",
      prompt: "prompt26",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "Smart Casual",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/modern/smart-casual-1.webp",
      prompt: "prompt27",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
    {
      name: "T-Shirt & Cargos",
      type: "modern",
      image:
        "https://lensify-user-images.s3.eu-central-1.amazonaws.com/dream-avatars-styles-images/boys/modern/tshirt-cargo.webp",
      prompt: "prompt28",
      sdModel: "SDXLFaetastic_v24",
      price: 59,
    },
  ],
};
