import { FC, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import useSetUser from "../hooks/useSetUser";

import Theme from "../constants/Theme";
import Filter from "../components/Filter";
import OrderCard from "../components/OrderCard";
import useFetchOrdersList from "../hooks/useFetchOrdersList";
import CircularLoader from "../components/CircularLoader";
import { useSelector } from "react-redux";
import { memoizedSelectApp } from "../context/selectors/app.selector";
import { isMobileDevice } from "../utils/browser.util";
import useCheckAdminAccess from "../hooks/useCheckAdminAccess";
import useGoogleSignIn from "../hooks/useGoogleSignIn";
import Snackbar from "../components/Snackbar";
import useSnackbar from "../hooks/useSnackbar";
import Error from "../components/Error";
import { CircularProgress } from "@mui/material";

const isMobile = isMobileDevice();

interface IHomeProps {
  user: any;
}

const Home: FC<IHomeProps> = ({ user }) => {
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);

  const { loggedInUser, showSnackbar, snackbarMessage, snackbarSeverity } =
    useSelector(memoizedSelectApp);

  const { setLoggedInUser } = useSetUser();

  const { checkAdminAccess } = useCheckAdminAccess();

  const { handleLogout } = useGoogleSignIn();

  const { closeSnackbar } = useSnackbar();

  const {
    email,
    toDate,
    // endDate,
    fromDate,
    jobStatus,
    // startDate,
    ordersList,
    fetchingOrderList,
    fetchingOrdersListError,
    fetchingMoreOrders,
    ordersListEnd,
    page,
    group,
    fetchOrdersList,
    handleEmailOnChange,
    handleToDateOnChange,
    handleFromDateOnChange,
    handleJobStatusOnChange,
    fetchMoreOrders,
    setPage,
    handleGroupsOnChange,
  } = useFetchOrdersList();

  const handleFilter = () => {
    fetchOrdersList(loggedInUser?.idToken);
  };

  const isAdmin = async () => {
    const { accessToken, email } = user;
    const payload = {
      email,
    };
    const { isAdmin, role } = await checkAdminAccess(accessToken, payload);
    if (isAdmin) {
      user.role = role;
      setLoggedInUser(user);
    } else {
      handleLogout();
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container &&
      Math.abs(
        container.scrollHeight - container.scrollTop - container.clientHeight
      ) <= 1 &&
      !fetchingMoreOrders
    ) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (page > 1 && !ordersListEnd) fetchMoreOrders(loggedInUser?.idToken);
  }, [page, ordersListEnd]);

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      isAdmin();
    }
  }, [user]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [fetchingOrderList]);

  // useEffect(() => {
  //   if (startDate !== "" && endDate !== "") {
  //     fetchOrdersList(user.accessToken);
  //   }
  // }, [startDate, endDate]);

  return loggedInUser?.idToken ? (
    <Container>
      <Filter
        email={email}
        toDate={toDate}
        fromDate={fromDate}
        jobStatus={jobStatus}
        group={group}
        fetchingOrderList={fetchingOrderList}
        handleFilter={handleFilter}
        handleEmailOnChange={handleEmailOnChange}
        handleToDateOnChange={handleToDateOnChange}
        handleFromDateOnChange={handleFromDateOnChange}
        handleJobStatusOnChange={handleJobStatusOnChange}
        handleGroupsOnChange={handleGroupsOnChange}
      />
      {fetchingOrderList ? (
        <CircularLoader />
      ) : (
        <OrdersContainer ref={containerRef}>
          {fetchingOrdersListError === "" ? (
            ordersList?.length ? (
              <>
                <Text>Orders</Text>
                <CardContainer>
                  {ordersList?.map((item: any) => (
                    <OrderCard
                      key={item?.order?.transactionId}
                      email={item?.order?.email}
                      order={item?.order}
                      loggedInUser={loggedInUser}
                    />
                  ))}
                </CardContainer>
                {fetchingMoreOrders && (
                  <CircularProgress color="inherit" size={24} />
                )}
              </>
            ) : (
              <div>No orders. Please modify / apply filter.</div>
            )
          ) : (
            <Error errorMessge={fetchingOrdersListError} />
          )}
        </OrdersContainer>
      )}
      <Snackbar
        open={showSnackbar}
        vertical="bottom"
        horizontal="center"
        message={snackbarMessage}
        //@ts-ignore
        severity={snackbarSeverity}
        handleClose={closeSnackbar}
      />
    </Container>
  ) : (
    <CircularLoader />
  );
};

export default Home;

const Container = styled.div`
  top: 70px;
  bottom: 0;
  padding: 16px;
  margin: 4px;
  border-radius: 4px;
  height: calc(100vh - 82px);
  box-sizing: border-box;
  // border: 2px solid ${Theme.COLORS.DARK_MAGENTA};
  overflow: auto;
  ::-webkit-scrollbar: {
    display: none;
  }
  scrollbar-width: none;
`;

const OrdersContainer = styled.div`
  height: ${isMobile ? "calc(100% - 267px)" : "calc(100% - 100px)"};
  margin-top: 30px;
  overflow: auto;
  ::-webkit-scrollbar: {
    display: none;
  }
  scrollbar-width: none;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  // height: calc(100% - 192px);
  // overflow-y: scroll;
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  // grid-template-rows: repeat(5, 1fr);
  // gap: 6px;
`;

const Text = styled.p`
  // margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
  color: ${Theme.COLORS.BLACK_RUSSIAN};
`;
