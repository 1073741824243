import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";

import Theme from "../constants/Theme";

const SideDrawer = () => {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate("/home");
  };

  const handleAddData = () => {
    navigate("/add-data");
  };

  const DrawerList = (
    <Box sx={{ width: 246 }} role="presentation">
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleHome}>
            <ListItemIcon>
              <HomeIcon sx={{ color: Theme.COLORS.TEXT_SECONDARY_COLOR }} />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleAddData}>
            <ListItemIcon>
              <AddIcon sx={{ color: Theme.COLORS.TEXT_SECONDARY_COLOR }} />
            </ListItemIcon>
            <ListItemText primary="Add Data" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer
        variant="permanent"
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            top: "76.4px",
            bottom: 0,
            height: "calc(100vh - 82px)",
            marginLeft: "3px",
            borderRadius: "4px",
            color: Theme.COLORS.WHITE,
            background: Theme.COLORS.BLACK_RUSSIAN,
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default SideDrawer;
