export const GROUPS = [
  {
    value: "group1",
    label: "Group 1",
  },
  {
    value: "group2",
    label: "Group 2",
  },
  {
    value: "group3",
    label: "Group 3",
  },
  {
    value: "group4",
    label: "Group 4",
  },
  {
    value: "group5",
    label: "Group 5",
  },
  {
    value: "group6",
    label: "Group 6",
  },
];
