import axios from "axios";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { SelectChangeEvent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setOrdersList } from "../context/slices/app.slice";
import { memoizedSelectApp } from "../context/selectors/app.selector";

const LIMIT = 10;

const url =
  "https://us-central1-mas-lensify.cloudfunctions.net/api/dream-avatars/fetch-all-orders";

const useFetchOrdersList = () => {
  const dispatch = useDispatch();

  const { loggedInUser, ordersList } = useSelector(memoizedSelectApp);

  const today = dayjs();

  const [email, setEmail] = useState("");
  const [jobStatus, setJobStatus] = useState("REVIEWING");
  const [toDate, setToDate] = useState<Dayjs | null>(today);
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    today.subtract(10, "day")
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [group, setGroup] = useState("group1");

  const [fetchingOrderList, setFetchingOrdersList] = useState(false);
  // const [ordersList, setOrdersList] = useState([]);
  const [fetchingOrdersListError, setFetchingOrdersListError] = useState("");

  const [page, setPage] = useState(1);
  const [cursor, setCursor] = useState(null);
  const [fetchingMoreOrders, setFetchingMoreOrders] = useState(false);
  const [fetchMoreOrdersError, setFetchMoreOrdersError] = useState("");
  const [ordersListEnd, setOrdersListEnd] = useState(false);

  const handleEmailOnChange = (e: SelectChangeEvent) => {
    setEmail(e.target.value);
  };

  const handleFromDateOnChange = (value: Dayjs | null) => {
    setFromDate(value);
    const date = new Date(dayjs(value).format("YYYY-MM-DD"));
    const startDate = date.toISOString();
    setStartDate(startDate);
  };

  const handleToDateOnChange = (value: Dayjs | null) => {
    setToDate(value);
    const date = new Date(dayjs(value).format("YYYY-MM-DD"));
    const endDate = date.toISOString();
    setEndDate(endDate);
  };

  const handleJobStatusOnChange = (event: SelectChangeEvent) => {
    setJobStatus(event.target.value as string);
  };

  const handleGroupsOnChange = (event: SelectChangeEvent) => {
    setGroup(event.target.value as string);
  };

  const fetchOrdersList = async (token: string) => {
    setOrdersListEnd(false);
    setFetchingOrdersList(true);
    dispatch(setOrdersList([]));
    setFetchingOrdersListError("");
    try {
      const payload = {
        email,
        fromDate: startDate,
        toDate: endDate,
        jobStatus,
        limit: LIMIT,
        cursor: null,
        assignedTo: group,
        role: loggedInUser?.role,
      };
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setOrdersList(response.data.orders));
      setCursor(response.data.lastOrder);
    } catch (error: any) {
      console.log("[Error] fetch orders", error.message);
      setFetchingOrdersListError(error.message);
    } finally {
      setFetchingOrdersList(false);
    }
  };

  const fetchMoreOrders = async (token: string) => {
    if (cursor) {
      setFetchingMoreOrders(true);
      try {
        const payload = {
          email,
          fromDate: startDate,
          toDate: endDate,
          jobStatus,
          limit: LIMIT,
          cursor,
          assignedTo: loggedInUser?.email,
          role: loggedInUser?.role,
        };
        const response = await axios.post(url, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCursor(response.data.lastOrder);
        dispatch(setOrdersList([...ordersList, ...response.data.orders]));
        if (response.data.orders.length === 0 && !response.data.lastOrder) {
          setOrdersListEnd(true);
        }
      } catch (error: any) {
        console.log("[Error] fetch more orders", error.message);
        setFetchMoreOrdersError(error.message);
      } finally {
        setFetchingMoreOrders(false);
      }
    }
  };

  const updateOrderList = (txnId: string, action: string) => {
    const newOrdersList = JSON.parse(JSON.stringify(ordersList));
    const foundOrder: any = newOrdersList.find(
      (order: any) => order?.order?.transactionId === txnId
    );
    if (foundOrder) {
      if (action === "approve") {
        foundOrder.order.jobStatus = "PROCESSING";
      }
      if (action === "disapprove") {
        foundOrder.order.jobStatus = "ERROR";
      }
      if (action === "done") {
        foundOrder.order.jobStatus = "DONE";
      }
      dispatch(setOrdersList(newOrdersList));
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      const startDate = new Date(fromDate.format("YYYY-MM-DD"));
      setStartDate(startDate.toISOString());
      const date = new Date(dayjs(toDate).format("YYYY-MM-DD"));
      date.setUTCHours(23, 59, 59, 999);
      const endDate = date.toISOString();
      setEndDate(endDate);
    }
  }, [fromDate, toDate]);

  return {
    email,
    toDate,
    fromDate,
    startDate,
    endDate,
    jobStatus,
    group,
    fetchingOrderList,
    ordersList,
    fetchingOrdersListError,
    fetchingMoreOrders,
    fetchMoreOrdersError,
    ordersListEnd,
    page,
    handleToDateOnChange,
    handleFromDateOnChange,
    fetchOrdersList,
    updateOrderList,
    handleEmailOnChange,
    handleJobStatusOnChange,
    fetchMoreOrders,
    setFetchMoreOrdersError,
    setPage,
    handleGroupsOnChange,
  };
};

export default useFetchOrdersList;
