export const MEN_PROMPTS_DATA = {
  prompt1: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt}, fit body, wearing a stylish military cargo pants and sweatshirt, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 676883130,
    steps: 40,
    width: 1024,
  },
  prompt2: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt}, muscular gym body, wearing a stylish gym sports wear, matching shoes, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3033436860,
    steps: 40,
    width: 1024,
  },
  prompt3: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt}, fit body, wearing a stylish leather biker jacket, sunglasses, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3856093046,
    steps: 40,
    width: 1024,
  },
  prompt4: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt}, fit body, wearing a stylish sports wear, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2387191575,
    steps: 40,
    width: 1024,
  },
  prompt5: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt}, fit body, wearing a stylish and expensive streetwear, sunglasses, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background, riding bike.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2556220154,
    steps: 40,
    width: 1024,
  },
  prompt6: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, standing, posing for a picture, solo, {instance_prompt} wearing a stylish, expensive wedding sherwani, mathcing shoes, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 1451041606,
    steps: 40,
    width: 1024,
  },
  prompt7: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, expensive wedding sherwani, mathcing shoes, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2911184988,
    steps: 40,
    width: 1024,
  },
  prompt8: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, standing, posing for a picture, solo, {instance_prompt} wearing a stylish, wedding suit, mathcing shoes, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 926549374,
    steps: 40,
    width: 1024,
  },
  prompt9: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, standing, posing for a picture, solo, {instance_prompt} wearing a stylish, expensive wedding suit, mathcing shoes, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2669561460,
    steps: 40,
    width: 1024,
  },
  prompt10: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt}, fit body, wearing a stylish cardigan, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2633007011,
    steps: 40,
    width: 1024,
  },
  prompt11: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt}, fit body, wearing a stylish and expensive formal shirt and pant, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3582830014,
    steps: 40,
    width: 1024,
  },
  prompt12: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "High-resolution, full-body photograph of an ({instance_prompt}:1.1) suitable for a popular Instagram post. (Fashion-forward:1.3), (urban backdrop:1.4), capturing the contemporary and vibrant energy of Instagram's aesthetics.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3731559473,
    steps: 40,
    width: 1024,
  },
  prompt13: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "High-resolution, full-body photograph of an ({instance_prompt}:1.1) suitable for a popular Instagram post. (Fashion-forward:1.3), (urban backdrop:1.4), capturing the contemporary and vibrant energy of Instagram's aesthetics.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2,
    steps: 40,
    width: 1024,
  },
  prompt14: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "High-resolution, full-body photograph of an ({instance_prompt}:1.1) suitable for a popular Instagram post. (Fashion-forward:1.3), (urban backdrop:1.4), capturing the contemporary and vibrant energy of Instagram's aesthetics.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3402715964,
    steps: 40,
    width: 1024,
  },
  prompt15: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt}, fit body, wearing a stylish and expensive streetwear, sunglasses, matching shoes, wearing edgJG_style clothing, edgJG fashion, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2989490123,
    steps: 40,
    width: 1024,
  },
  prompt16: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt}, fit body, wearing a stylish modern casuals, matching shoes, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3293981682,
    steps: 40,
    width: 1024,
  },
  prompt17: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt}, fit body, wearing a stylish formals, matching shoes, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 358796504,
    steps: 40,
    width: 1024,
  },
  prompt18: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt}, fit body, wearing a stylish tshirt full sleeves and jeans, matching shoes, fit clothing, matching footwear, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 4111545057,
    steps: 40,
    width: 1024,
  },
  prompt19: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "portrait photo of ({instance_prompt}:1.1) wearing an expensive White suit, white background, fit.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
};
