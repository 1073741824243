export const BOYS_PROMPTS_DATA = {
  prompt1: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, photo of ({instance_prompt}:1.1) wearing a batman suit, street lighting, city background, dark night, fit.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt2: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing a black panther suit, city street background, dark night, fit.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt3: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing a captain america suit, dark night, fit.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt4: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing an ironman suit, street lighting, city background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt5: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing spiderman clothes, street lighting, city background, fit.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt6: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing superman clothes, rooftop, tech buildings in background, fit, blur background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt7: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing thor suit, thunder background, dark night, fit.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt8: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, portrait photo of ({instance_prompt}:1.1) wearing Wolverine suit, jungle background, dark night, fit.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt9: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, watermark, logo.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, hindu clothes, matching shoes, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 456338728,
    steps: 40,
    width: 1024,
  },
  prompt10: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing an expensive Festive & Party wedding sherwani, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 1676415109,
    steps: 40,
    width: 1024,
  },
  prompt11: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing an expensive Festive & Party wedding sherwani, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3408048863,
    steps: 40,
    width: 1024,
  },
  prompt12: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing an expensive Festive & Party Blazer and Pant Set, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 1251184078,
    steps: 40,
    width: 1024,
  },
  prompt13: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing an expensive Festive & Party wedding suit, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3063356286,
    steps: 40,
    width: 1024,
  },
  prompt14: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), portrait photo of ({instance_prompt}:1.1) wearing an expensive White suit, white background, fit.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt15: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, photo of ({instance_prompt}:1.1) wearing an orange hanuman attire, hanuman accessories, ashram in background, blur background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt16: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, full length photo of ({instance_prompt}:1.1) wearing a dubai sheikh attire, dubai sheikh fashion accessories, dubai in background, blur background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt17: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, photo of ({instance_prompt}:1.1) wearing lord krishna clothes, lord krishna accessories, ashram in background, blur background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt18: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, full length photo of ({instance_prompt}:1.1) wearing an orange lord ram attire, lord ram accessories, ashram in background, blur background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt19: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, photo of ({instance_prompt}:1.1) wearing a lord ram clothes, ashram in background, flying in sky, blur background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt20: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, leaning to wall, posing for a picture, solo, {instance_prompt} wearing an expensive Festive & Party full sleeves white Kurta, light blue Waistcoat and Pyjama, less glossy, wearing white sandals, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 898777327,
    steps: 40,
    width: 1024,
  },
  prompt21: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, photo of ({instance_prompt}:1.1) wearing lord krishna clothes, ashram in background, flying in sky, blur background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt22: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, standing, posing for a picture, photo of ({instance_prompt}:1.1) wearing hanuman clothes, street lighting, flying in sky, blur background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 82526794,
    steps: 40,
    width: 1024,
  },
  prompt23: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, army pattern pant and black t shirt full sleeves, neck chain, sunglasses, accessories, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 4248495072,
    steps: 40,
    width: 1024,
  },
  prompt24: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a army pant and black t shirt, chain, sunglasses, accessories, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 2443466959,
    steps: 40,
    width: 1024,
  },
  prompt25: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, korean clothes, matching shoes, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 3073000180,
    steps: 40,
    width: 1024,
  },
  prompt26: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "High-resolution, full-body photograph of {instance_prompt} suitable for a popular Instagram post. (Fashion-forward:1.3), (urban backdrop:1.4), capturing the contemporary and vibrant energy of Instagram's aesthetics",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 1794243945,
    steps: 40,
    width: 1024,
  },
  prompt27: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a stylish, korean clothes, fit clothing, full length, authentic background",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 1672416552,
    steps: 40,
    width: 1024,
  },
  prompt28: {
    alwayson_scripts: {},
    cfg_scale: 7,
    height: 1024,
    negative_prompt:
      "(low quality, worst quality:1.4), (holding, multiple limbs, mutated limbs, mutated human, poorly drawn human body:1.5), drawing, painting, crayon, sketch, graphite, impressionist, noisy, blurry, soft, deformed, ugly, nude, nsfw, mask, ugly hair.",
    override_settings: {
      CLIP_stop_at_last_layers: 1,
      sd_model_checkpoint: "",
      sd_vae: "Automatic",
    },
    prompt:
      "((Masterpiece, best quality, edgQuality, photorealistic, ultra photorealistic)), seductive smile, stylish, posing for a picture, solo, {instance_prompt} wearing a cargo military pant and white t shirt, accessories, fit clothing, full length, authentic background.",
    sampler_name: "DPM++ 2M SDE",
    scheduler: "Karras",
    seed: 907143072,
    steps: 40,
    width: 1024,
  },
};
