import { combineReducers, configureStore } from "@reduxjs/toolkit";

import appSlice from "./slices/app.slice";
// import fabAvatarsSlice from "./slices/fabAvatars.slice";

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  app: appSlice,
  //   fabAvatars: fabAvatarsSlice,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
