import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useCheckAdminAccess from "../hooks/useCheckAdminAccess";
import useGoogleSignIn from "../hooks/useGoogleSignIn";
import useSetUser from "../hooks/useSetUser";
import { useSelector } from "react-redux";
import { memoizedSelectApp } from "../context/selectors/app.selector";
import Theme from "../constants/Theme";
import useGoogleFirestore from "../hooks/useGoogleFirestore";
import { BOYS_STYLES_DATA } from "../constants/stylesData/BoysStylesData";
import { GIRLS_STYLES_DATA } from "../constants/stylesData/GirlsStylesData";
import { MEN_STYLES_DATA } from "../constants/stylesData/MenStylesData";
import { WOMEN_STYLES_DATA } from "../constants/stylesData/WomenStylesData";
import { BOYS_PROMPTS_DATA } from "../constants/promptsData/BoysPromptsData";
import { GIRLS_PROMPTS_DATA } from "../constants/promptsData/GirlsPromptsData";
import { MEN_PROMPTS_DATA } from "../constants/promptsData/MenPromptsData";
import { WOMEN_PROMPTS_DATA } from "../constants/promptsData/WomenPromptsData";

interface IAddData {
  user: any;
}

const AddData: FC<IAddData> = ({ user }) => {
  const { loggedInUser } = useSelector(memoizedSelectApp);

  const navigate = useNavigate();
  const { setLoggedInUser } = useSetUser();
  const { handleLogout } = useGoogleSignIn();
  const { checkAdminAccess } = useCheckAdminAccess();
  const { addDreamAvatarsStylesData, addDreamAvatarsPromptsData } =
    useGoogleFirestore();

  const [stylesCollectionName, setStylesCollectionName] =
    useState("dreamAvatarsStyles");
  const [documentName, setDocumentName] = useState("boys");
  const [promptsCollectionName, setPromptsCollectionName] = useState(
    "dreamAvatarsPrompts"
  );
  const [sdModelName, setSdModelName] = useState("SDXLFaetastic_v24");

  const isAdmin = async () => {
    const { accessToken, email } = user;
    const payload = {
      email,
    };
    const { isAdmin, role } = await checkAdminAccess(accessToken, payload);
    if (isAdmin) {
      user.role = role;
      setLoggedInUser(user);
    } else {
      handleLogout();
    }
  };

  const handleStylesCollectionNameOnChange = (event: SelectChangeEvent) => {
    setStylesCollectionName(event.target.value as string);
  };

  const handleDocumentNameOnChange = (event: SelectChangeEvent) => {
    setDocumentName(event.target.value as string);
  };

  const handlePromptsCollectionNameOnChange = (event: SelectChangeEvent) => {
    setPromptsCollectionName(event.target.value as string);
  };

  const handleSdModelNameOnChange = (event: SelectChangeEvent) => {
    setSdModelName(event.target.value as string);
  };

  const getStylesData = (): any => {
    let data: any = [];
    if (documentName === "boys") {
      data = BOYS_STYLES_DATA;
    }
    if (documentName === "girls") {
      data = GIRLS_STYLES_DATA;
    }
    if (documentName === "men") {
      data = MEN_STYLES_DATA;
    }
    if (documentName === "women") {
      data = WOMEN_STYLES_DATA;
    }
    return data;
  };

  const getPromptsData = (): any => {
    let data: any = [];
    if (documentName === "boys") {
      data = BOYS_PROMPTS_DATA;
    }
    if (documentName === "girls") {
      data = GIRLS_PROMPTS_DATA;
    }
    if (documentName === "men") {
      data = MEN_PROMPTS_DATA;
    }
    if (documentName === "women") {
      data = WOMEN_PROMPTS_DATA;
    }
    return data;
  };

  const handleAddStylesData = () => {
    const token = loggedInUser?.idToken;
    const payload = {
      collectionName: stylesCollectionName,
      documentName,
      data: getStylesData(),
    };
    addDreamAvatarsStylesData(token, payload);
  };

  const handleAddPromptsData = () => {
    const token = loggedInUser?.idToken;
    const payload = {
      collectionName: promptsCollectionName,
      documentName,
      sdModelName,
      data: getPromptsData(),
    };
    addDreamAvatarsPromptsData(token, payload);
  };

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      isAdmin();
    }
  }, [user]);

  return (
    <Container>
      {loggedInUser?.role === "admin" ? (
        <>
          {/* Styles */}
          <HeaderText>Add Styles Data</HeaderText>
          <FieldContainer>
            <FormControl fullWidth>
              <InputLabel id="styles-collection-name">
                Styles Collection Name
              </InputLabel>
              <Select
                labelId="styles-collection-name"
                id="styles-collection-select"
                value={stylesCollectionName}
                label="Styles Collection Name"
                onChange={handleStylesCollectionNameOnChange}
              >
                <MenuItem value="dreamAvatarsStyles">
                  Dream Avatars Styles
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mx: 2 }}>
              <InputLabel id="styles-document-name">Document Name</InputLabel>
              <Select
                labelId="styles-document-name"
                id="styles-document-select"
                value={documentName}
                label="Document Name"
                onChange={handleDocumentNameOnChange}
              >
                <MenuItem value="boys">Boys</MenuItem>
                <MenuItem value="girls">Girls</MenuItem>
                <MenuItem value="men">Men</MenuItem>
                <MenuItem value="women">Women</MenuItem>
              </Select>
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              sx={{
                height: 54,
                mb: "1px",
                fontWeight: "bold",
                bgcolor: Theme.COLORS.IRIS_BLUE,
                color: Theme.COLORS.TEXT_PRIMARY_COLOR,
              }}
              onClick={handleAddStylesData}
            >
              Add Dream Avatars Styles
            </Button>
          </FieldContainer>
          {/* Prompts */}
          <HeaderText>Add Prompts Data</HeaderText>
          <FieldContainer>
            <FormControl fullWidth>
              <InputLabel id="prompts-collection-name">
                Prompts Collection Name
              </InputLabel>
              <Select
                labelId="prompts-collection-name"
                id="prompts-collection-select"
                value={promptsCollectionName}
                label="Prompts Collection Name"
                onChange={handlePromptsCollectionNameOnChange}
              >
                <MenuItem value="dreamAvatarsPrompts">
                  Dream Avatars Prompts
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ ml: 2 }}>
              <InputLabel id="prompts-document-name">Document Name</InputLabel>
              <Select
                labelId="prompts-document-name"
                id="prompts-document-select"
                value={documentName}
                label="Document Name"
                onChange={handleDocumentNameOnChange}
              >
                <MenuItem value="boys">Boys</MenuItem>
                <MenuItem value="girls">Girls</MenuItem>
                <MenuItem value="men">Men</MenuItem>
                <MenuItem value="women">Women</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mx: 2 }}>
              <InputLabel id="sd-model-collection">Sd Model Name</InputLabel>
              <Select
                labelId="sd-model-collection"
                id="sd-model-select"
                value={sdModelName}
                label="Document Name"
                onChange={handleSdModelNameOnChange}
              >
                <MenuItem value="SDXLFaetastic_v24">SDXLFaetastic_v24</MenuItem>
              </Select>
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              sx={{
                height: 54,
                mb: "1px",
                fontWeight: "bold",
                bgcolor: Theme.COLORS.IRIS_BLUE,
                color: Theme.COLORS.TEXT_PRIMARY_COLOR,
              }}
              onClick={handleAddPromptsData}
            >
              Add Dream Avatars Prompts
            </Button>
          </FieldContainer>
        </>
      ) : (
        "You don't have access to this page"
      )}
    </Container>
  );
};

export default AddData;

const Container = styled.div`
  top: 70px;
  bottom: 0;
  padding: 16px;
  margin: 4px;
  border-radius: 4px;
  height: calc(100vh - 82px);
  box-sizing: border-box;
  overflow: auto;
  ::-webkit-scrollbar: {
    display: none;
  }
  scrollbar-width: none;
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 32px;
`;
